<template>
    <Modal title="查看资产使用记录" v-model="showForm" :mask-closable="false">
        <Form class="plain-form" ref="viewForm" label-colon :label-width="90">
            <div class="divider">资产基本信息</div>
                <FormItem label="资产编码">{{ viewForm.encoding }}</FormItem>
                <FormItem label="资产名称">{{ viewForm.name }}</FormItem>
                <FormItem label="资产分类">{{ viewForm.classifyName }}</FormItem>
                <FormItem label="品牌">{{ viewForm.brand }}</FormItem>
                <FormItem label="型号">{{ viewForm.model }}</FormItem>
                <FormItem label="SN码">{{ viewForm.snCode }}</FormItem>
            <div class="divider">资产使用记录</div>
            <Steps direction="vertical" :current="recordLength-1">
                <Step :title="item.transferName" v-for="item in dataList" :key="item.id">
                    <div slot="content" v-if="item.referenceType === 'distribute'">
                        使用人：{{item.userName}}，{{item.transferName}}时间：{{$moment(item.transferTime).format('YYYY-MM-DD')}}
                    </div>
                    <div slot="content" v-else-if="item.referenceType === 'returning'">
                        申请人：{{item.userName}}，申请时间：{{$moment(item.transferTime).format('YYYY-MM-DD')}}
                    </div>
                    <div slot="content" v-else-if="item.referenceType === 'repair'">
                        报修人：{{item.userName}}，报修时间：{{$moment(item.transferTime).format('YYYY-MM-DD')}}
                    </div>
                    <div slot="content" v-else>
                        {{item.transferName}}人：{{item.userName}}，{{item.transferName}}时间：{{$moment(item.transferTime).format('YYYY-MM-DD')}}
                    </div>
                    <div slot="content" v-if="item.operatorList !== null" v-for="element in item.operatorList" :key="element.id">
                        <div :style="{color: element.operationStatus === 1 ? '#ed4014' : ''}">
                            审批人：{{element.operatornName}}，审批状态：{{element.operationStatus === 1 ? '驳回' : '通过'}}，审批时间：{{element.operationTime}}
                        </div>
                    </div>
                </Step>
            </Steps>
        </Form>
        <div slot="footer">
            <Button type="primary" @click="closeModal(false)">确定</Button>
        </div>
    </Modal>
</template>

<script>
import { reqViewAth, reqGetAssetById } from '../../../api/asset-api';

export default {
    name: 'viewRecord',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        assetId: ''
    },
    data() {
        return {
            showForm: false,
            viewForm: {
            },
            dataList: [],
            recordLength: 0,
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            if (val) {
                this.getAssetById();
                this.doSearching();
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
    methods: {
        closeModal(val) {
            this.$emit('input', val);
        },
        getAssetById(){
            reqGetAssetById({id: this.assetId}).then((res)=>{
                this.viewForm = res.data.data;
            }).catch(()=>{
                this.viewForm = [];
            })
        },
        doSearching() {
            reqViewAth({assetId: this.assetId}).then((res) => {
                this.dataList = res.data.data;
                this.recordLength = res.data.data.length;
            }).catch(() => {
                this.dataList = [];
            });
        }
    },
    mounted() {
    }
}
</script>
